.cabecalho {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff49;
  width: 100%;
  z-index: 1000;
}

.cabecalho-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.cabecalho-principal {
  font-family: Roboto;
  color: #445964;
  font-weight: 900;
  font-size: 40px;
  text-decoration: none;
}

.cabecalho-menu-mobile {
  display: none;
  font-size: 32px;
  color: #445964;
  cursor: pointer;
}

.cabecalho-menu {
  display: flex;
  gap: 32px;
}

.cabecalho-menu-item {
  color: #445964;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  text-decoration: none;
}

.cabecalho-menu-item:hover {
  color: blueviolet;
  cursor: pointer;
}

/* Responsividade */
@media (max-width: 768px) {
  .cabecalho-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 72px;
    right: 24px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    border-radius: 8px;
  }

  .cabecalho-menu.show {
    display: flex;
  }

  .cabecalho-menu-mobile {
    display: block;
  }
}
