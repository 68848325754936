.conteudo-sessao2-escrito-titulo {
    color: #445964;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    width: 100%;
    margin-top: 30px;
  }
  
  .conteudo-sessao2-escrito-subtitulo {
    color: #445964;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;

  }
  
  .conteudo-sessao2-escrito {
    display: flex;
    flex-direction: column;
  }
  
  .conteudo-sessao2 {
    display: flex;
    gap: 32px;
    flex-direction: column;
    margin-top: 0px;
    padding: 0 20px;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .conteudo-sessao2 {
      box-sizing: content-box;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }

  }


