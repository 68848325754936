.conteudo-sessao4-escrito-titulo {
    color: #445964;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    width: 100%;
    margin-top: 30px;
  }
  
  
  .conteudo-sessao4-escrito-subtitulo {
    color: #445964;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 60px;
  
  }
  
  .conteudo-sessao4-escrito {
    display: flex;
    flex-direction: column;
    margin-top: -40px;
  }
  
  .conteudo-sessao4 {
    /*
    margin-right: 115px;
    margin-bottom: 60px;
    */
  }
  
  .imagens-cargos {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
    gap: 0px;
    align-items: center;
  }
  
  .conteudo-sessao4-cargo2, .conteudo-sessao4-cargo4 {
    margin-top: -10px;
  }
  
  .titulo1, .titulo3 {
    position: relative;
    text-align: center;
    color: #445964;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 20px;
  }
  
  .conteudo-sessao4-cargo1, .conteudo-sessao4-cargo3 {
    background-image: url("../../images/cargo1.png");
    width: 100%;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
    border-radius: 10px;
  }
  
  .titulo2, .titulo4 {
    position: relative;
    text-align: center;
    color: #263138;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 20px;
  }
  
  .conteudo-sessao4-cargo2, .conteudo-sessao4-cargo4 {
    background-image: url("../../images/cargo2.png");
    width: 100%;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
    border-radius: 10px;
  }
  
  .texto1 {
    color: #FFF;
    text-align: center;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 16px;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .conteudo-sessao4-escrito2 {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    gap: 5px;
  }
  
  .conteudo-sessao4-aspas {
    width: 40px;
    height: 40px;
    margin-top: 25px;
  }
  
  .conteudo-sessao4-texto {
    color: #445964;
    text-align: center;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 300px;
    margin-top: 0px;
  
  }
  
  .conteudo-sessao4-texto2 {
    color: #263138;
    text-align: center;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: -16px;
    margin-bottom: 30px;
  }

   /* Responsividade */
   @media (max-width: 768px) {
    .conteudo-sessao4 {
      width: 90%;
      box-sizing: content-box;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    .imagens-cargos {
      flex-wrap: wrap;
      gap: 10px;
      order: 2;
      height: auto;
    }

    .conteudo-sessao4-escrito {
      order: 1;
    }

    .conteudo-sessao4-escrito2 {
      position: relative;
      order: 3;
    }

    .conteudo-sessao4-escrito-titulo {
      margin-top: 30px;
    }

    .conteudo-sessao4-cargo2, .conteudo-sessao4-cargo4 {
      margin-top: auto;
    }
  }