.conteudo-sessao0 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 120px;
    width: 100%;
  }

  .conteudo-sessao0-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
  
  .conteudo-sessao0-imagem {
    width: 400px;
    height: auto;
    border-radius: 100%;
  }
  
  .conteudo-sessao0-escrito {
    display: flex;
    gap: 32px;
    flex-direction: column;
    justify-content: space-between;
    width: 545px;
    height: 240px;
    margin-left: 30px;
    margin-top: -170px;
  }
  
  .conteudo-sessao0-escrito-titulo {
    color: #445964;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: -20px;
  }
  
  .conteudo-sessao0-escrito-subtitulo {
    color: #445964;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: -10px;
  }
  
  .conteudo-sessao0-escrito-subtitulo-continuaçao {
    color: #445964;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .conteudo-sessao0-escrito-botao {
    display: flex;
    width: 258px;
    padding: 16px 32px;
    align-items: center;
    gap: 8px;
    align-self: flex-start;
    background: #263138;
    border-radius: 15px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
  }
  
  .conteudo-sessao0-escrito-botao:hover {
    background: #445964;
  }
  
  .redes-sociais {
    display: flex;
    gap: 16px;
    margin-top: 20px;
  }
  
  .redes-sociais a img {
    width: 40px;
    height: 40px;
  }
  
  /* Responsividade */
@media (max-width: 768px) {
    .conteudo-sessao0 {
      flex-direction: column;
      align-items: center;
      margin-top: 80px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
  
    .conteudo-sessao0-container {  
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
    }
  
    .conteudo-sessao0-imagem {
      width: 200px;
      order: 1;
      margin-top: 20px;
    }
  
    .conteudo-sessao0-escrito {
      text-align: start;
      order: 2;
      margin-top: auto;
      margin-left: 0;
      margin-bottom: 20px;
      width: 100%;
    }

    .conteudo-sessao0-escrito-titulo {
      text-align: start;
    }
  
    .conteudo-sessao0-escrito-subtitulo {
      text-align: start;
      font-size: 40px;
    }
  
    .conteudo-sessao0-escrito-subtitulo-continuaçao {
      text-align: start;
    }

  }